import React from 'react';
import { Link } from 'gatsby';
import HeaderBack from '../components/header-back';
import Box from '../components/box';
import Promo from '../components/promo';
import Layout from '../components/layout';
import { Helmet } from 'react-helmet';

const IndexPage = () => (
  <Layout>
    <Helmet>
      <title>Главная</title>
      <meta
        name="description"
        content="Я есмь Путь, Истина и Жизнь. Собрание во имя Господа. Матфея 18:20"
      />
      <meta
        name="keywords"
        content="Путь, Истина, Жизнь, Собрание, Церковь, Матфея 18:20, GBV, Украина, Запорожье, Мукачево"
      />
    </Helmet>
    <HeaderBack fullPage textCenter>
      <h1 className="page-title">Я есмь Путь, Истина и Жизнь</h1>
      <h2 className="page-description">Ев. от Иоанна 14,6</h2>
    </HeaderBack>
    <div id="content">
      <div className="container">
        <Promo className="helper pt60" iconCode={0xe66a} title="О чем">
          На этих страницах вы найдете информацию о собирании к имени Господа,
          основанием которого является учение, изложенное в Слове Бога — Библии.
        </Promo>
        <div className="row">
          <div className="col-md-4">
            <Box title="Путь">
              Господь является единственным путем познания истинного Бога как
              любящего Отца и вечного пребывания с Ним на небесах в славе.
              "Никто не приходит к Отцу, как только через Меня" (Ев. от Иоанна
              14,6)
            </Box>
          </div>
          <div className="col-md-4">
            <Box title="Истина">
              Что есть истина? Иисус Христос открывает ее всем тем, кто приходит
              к нему с открытым сердцем. Он предлагает человеку увидеть все
              Божьими глазами: каков Бог в Его любви и благодати, каков человек
              пред Богом.
            </Box>
          </div>
          <div className="col-md-4">
            <Box title="Жизнь">
              Как достичь вечной жизни? Как не бояться смерти? "Я есмь
              воскресение и жизнь; верующий в Меня, если и умрет, оживет" (Ев.
              от Иоанна 11,25)
            </Box>
          </div>
        </div>
        <Promo title="Основание" iconCode={0xe629} className="">
          Где двое или трое собраны во имя Мое, там Я посреди них. (Ев. от
          Матфея 18,20)
        </Promo>
        <div className="helper center">
          <Link to="/assembly" className="faq-grid-show-more">
            Подробнее
            <i className="fa fa-angle-right" />
          </Link>
        </div>
      </div>

      <div className="call-to-action helper mt60">
        <div className="container">
          <h3 className="call-to-action-title"> Желаете посетить собрание? </h3>
          <p className="call-to-action-description">
            Найдите ближайший к вам город, в котором есть собрание к имени
            Господа
          </p>
          <div className="call-to-action-buttons">
            <Link to="/contacts" className="call-to-action-button">
              Контакты
            </Link>
          </div>
        </div>
      </div>

      <div className="container">
        <Promo title="Читайте онлайн" iconCode={0xe675}>
          Ресурсы для чтения и изучения в электронном виде
        </Promo>
        <div className="row">
          <div className="col-md-4">
            <Box
              title="Календари 2020"
              icon={<i className="fa fa-calendar box-icon-large"></i>}>
              На этом ресурсе представлены онлайн календари на каждый день
              "Доброе семя" и "Господь близко" (текущего года) на русском,
              украинском и других языках.
              <a
                href="https://gbv-online.org/calendar"
                target="_blank"
                rel="noreferrer nofollow"
                className="button blue stroke rounded">
                Читать
              </a>
            </Box>
          </div>
          <div className="col-md-4">
            <Box
              title="Литература GBV"
              icon={<i className="fa fa-folder-open-o box-icon-large"></i>}>
              На сайте GBV-Online представлена большая часть литературы,
              изданной GBV (не только на русском языке), в каталогизированном
              виде удобном для чтения.
              <a
                href="https://gbv-online.org"
                target="_blank"
                rel="noreferrer nofollow"
                className="button blue stroke rounded">
                Открыть
              </a>
            </Box>
          </div>
          <div className="col-md-4">
            <Box
              title="Синопсис Дарби"
              icon={<i className="fa fa-book box-icon-large"></i>}>
              Новый перевод Синопсиса книг Библии Джона Н. Дарби в специальном
              онлайн-формате с разбивкой по книгам и главам для удобства
              навигации.
              <a
                href="https://synopsis.way-truth-life.org"
                target="_blank"
                rel="noreferrer nofollow"
                className="button blue stroke rounded">
                Изучать
              </a>
            </Box>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
