import React from 'react';
import classNames from 'classnames';

const Box = ({ className = 'helper center', title, image, icon, children }) => {
  return (
    <div className={classNames('box', className, { 'box-image': !!image })}>
      {!!image && <img src={image} alt="box icon" className="image" />}
      {icon}
      <h4 className="box-title">{title}</h4>
      {React.Children.map(children, (child) => (
        <p className="box-description">{child}</p>
      ))}
    </div>
  );
};

export default Box;
