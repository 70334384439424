import React from 'react'

const Promo = ({ title, iconCode, className = '', children }) => {
  return (
    <div className={`promo-title-wrapper ${className}`}>
      <h3 className="promo-title" data-icon={String.fromCharCode(iconCode)}>
        {title}
      </h3>
      <p className="promo-description">{children}</p>
    </div>
  )
}

export default Promo
